<script setup>
import {
  ref,
  onMounted,
  nextTick,
  onUnmounted,
  getCurrentInstance,
  computed,
  reactive
} from "vue";
import zulipInit from "zulip-js";
import URLS from "../../config";
import MultiSelectDropdown from "./MultiSelectDropdown.vue";
import UserGroupIcon from "./UserGroupIcon.vue";

const isOpen = ref(false);
const typedMessaige = ref("");
const totalMesaige = ref([]);
const selectedMszId = ref("");
const isDeleting = ref(false);
const isSending = ref(false);
const isEditMsz = ref(false);
const editableMsz = ref(null);
const editableIndex = ref(-1);
const zulip = ref(null);
const apiKey = ref("");
const allUsers = ref([]);
const selectedGroupUsers = ref([]);
const selectedUsers = ref([]);
const selectedGroup = ref("");
const { proxy } = getCurrentInstance();
const userGroupe = ref();
let zulipConfigDet = ref({});
const groupeMemberIds = ref([]);
const userGrouplist = ref([]);
const chatBotUser = ref(null);
const chatContainerStyle = reactive({
  left:"1px"
});
const chatBoxStyle = reactive({
  left:"1px"
});

function setIsOpen() {
  if (zulip.value && zulip.value.config.apiKey) {
    isOpen.value = !isOpen.value;
    if (isOpen.value && totalMesaige.value.length > 0) {
      setTimeout(() => {
        scrollToButtom(true);
      }, 300);
    }
  } else {
    proxy.$toast.error("Access denied!", {
      duration: "2000",
      position: "top",
      pauseOnHover: true,
    });
  }
}
async function sendMessaige(file = false, uri) {
  if (typedMessaige.value != "") {
    isSending.value = true;
    // Send a message
    const res = await zulip.value.messages.send({
      to:
        selectedUsers.value.length > 0 ? selectedUsers.value : userGroupe.value,
      type: selectedUsers.value.length > 0 ? "direct" : "stream",
      subject: "test",
      content: typedMessaige.value,
    });
    if (res.result == "success") {
      if (!file) {
        totalMesaige.value.push({
          content: typedMessaige.value,
          type: "sent",
          client: "Mozilla",
          id: res.id,
          sender_id:chatBotUser.value.user_id
        });
      } else {
        totalMesaige.value.push({
          content: typedMessaige.value,
          type: "sent",
          uri: uri,
          client: "Mozilla",
          id: res.id,
          sender_id:chatBotUser.value.user_id
        });
      }
      typedMessaige.value = "";
      if (!file) {
        // registerMesQueEvent();
      }
      nextTick(() => {
        scrollToButtom(true);
      });
    }
    isSending.value = false;
  }
}
async function registerMesQueEvent() {
  // Register a queue
  const params = {
    event_types: ["message"],
  };

  const { queue_id } = await zulip.value.queues.register(params);
  // Retrieve events from a queue with given "queue_id"
  const eventParams = {
    queue_id,
    last_event_id: -1,
  };
  const eventQ = await zulip.value.events.retrieve(eventParams);
  if (eventQ.result == "success") {
    if (eventQ.events.length > 0 && eventQ.events[0].message) {
      let msz = eventQ.events[0].message.content;
      totalMesaige.value.push({ content: msz, type: "received" });
      nextTick(() => {
        scrollToButtom(true);
      });
    }
  }
  await zulip.value.queues.deregister({ queue_id: queue_id });
}
async function uploadAttachments(file) {
  const botEmailAddress = zulipConfigDet.value.username;
  const botApiKey = apiKey.value;
  const zulipDomain = zulipConfigDet.value.realm;

  const url = `${zulipDomain}/api/v1/user_uploads`;

  const formData = new FormData();
  formData.append("filename", file);

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Basic " + btoa(`${botEmailAddress}:${botApiKey}`),
    },

    body: formData,
  });
  const data = await res.json();
  if (data.result == "success") {
    typedMessaige.value = `[${file.name}](${data.uri})`;
    sendMessaige(true, data.uri);
  }
}
function uploadFile() {
  let input = document.createElement("input");
  input.type = "file";
  input.onchange = (_) => {
    // you can use this method to get file and perform respective operations
    let files = Array.from(input.files);
    if (files.length > 0) {
      uploadAttachments(files[0]);
    }
  };
  input.click();
}
function scrollToButtom(isFirst = false) {
  let chatDiv = document.getElementsByClassName("chat-messages");
  if (isFirst && chatDiv && chatDiv.length && chatDiv.length > 0) {
    chatDiv[0].scrollTop = chatDiv[0].scrollHeight;
  }
}
function resizeHandler(){
  chatContainerStyle.left =`${window.innerWidth-55}px`
  chatBoxStyle.left =`${window.innerWidth-410}px`;
}
onMounted(async () => {
  // await loggedInUserDetails();
  chatContainerStyle.left =`${window.innerWidth-55}px`
  chatBoxStyle.left =`${window.innerWidth-410}px`;
  window.addEventListener("resize", resizeHandler);
  setTimeout(() => {
    initChatBot();
  }, 7000);
});
onUnmounted(() => {
  zulip.value = null;
  window.removeEventListener("resize", resizeHandler);
});
async function getPreviousMezFromServer() {
  const readParams = {
    anchor: "newest",
    num_before: 100,
    num_after: 0,
    narrow: [{ operator: "stream", operand: userGroupe.value }],
  };

  // Get the 100 last messages sent by "santosh.k@apollodart.com" to the stream "general"
  let res = await zulip.value.messages.retrieve(readParams);
  if (res.result == "success") {
    totalMesaige.value = await prepareMessaige(
      res.messages.filter((m) => m.client != "Internal")
    );
    nextTick(() => {
      scrollToButtom(true);
    });
  }
}
async function prepareMessaige(messaiges) {
  let tempMsz = [];
  for (let i = 0; i < messaiges.length; i++) {
    let uri = "";
    let attachmentName = "";
    if (messaiges[i].content.includes("user_uploads")) {
      const pattern = /\<img.*?\>/gi;
      const re = /\ssrc=(?:(?:'([^']*)')|(?:"([^"]*)")|([^\s]*))/i;
      let temp = pattern.exec(messaiges[i].content);
      let imgSrc = re.exec(temp[0]);
      if (imgSrc && imgSrc.length == 4) {
        uri = imgSrc[2];
        let tempFile = imgSrc[2].split("/");
        let tempFileLength = tempFile.length;
        attachmentName = tempFile[tempFileLength - 1];
      }
    }
    tempMsz.push({
      content: messaiges[i].content
        .replaceAll("<p>", "")
        .replaceAll("</p>", ""),
      client: messaiges[i].client,
      uri: uri != "" ? uri : undefined,
      name: uri != "" ? attachmentName : undefined,
      id: messaiges[i].id,
      sender_id: messaiges[i].sender_id,
    });
  }
  return tempMsz;
}
function showMoreOptions(msz) {
  if (msz) {
    selectedMszId.value = selectedMszId.value == "" ? msz.id : "";
  }
}
async function deleteMszFromserver(id, ind) {
  isDeleting.value = true;
  const url = `${zulipConfigDet.value.realm}/api/v1/messages/${id}`;
  const res = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization:
        "Basic " + btoa(`${zulipConfigDet.value.username}:${apiKey.value}`),
    },
  });
  const data = await res.json();
  if (data.result == "success") {
    totalMesaige.value.splice(ind, 1);
  } else {
    proxy.$toast.error(data.msg, {
      duration: "2000",
      position: "top",
      pauseOnHover: true,
    });
  }
  isDeleting.value = false;
}
function editMszClickHandler(msz, ind) {
  const { content } = msz;
  typedMessaige.value = content;
  editableMsz.value = msz;
  editableIndex.value = ind;
  isEditMsz.value = true;
}
async function editMessaige() {
  isSending.value = true;
  // Update a message with the given "message_id"
  const params = {
    message_id: editableMsz.value.id,
    content: typedMessaige.value,
  };
  const res = await zulip.value.messages.update(params);
  if (res.result == "success") {
    //update content in chat ui
    totalMesaige.value[editableIndex.value].content = typedMessaige.value;
  } else {
    proxy.$toast.error(res.msg, {
      duration: "2000",
      position: "top",
      pauseOnHover: true,
    });
  }
  isSending.value = false;
  isEditMsz.value = false;
  editableMsz.value = null;
  typedMessaige.value = "";
  selectedMszId.value = "";
}
async function getAllUsers() {
  const res = await zulip.value.users.retrieve();
  if (res.result == "success") {
    allUsers.value = res.members.filter(
      (e) => e.is_bot == false && e.email != zulipConfigDet.value.username
    );
    // groupeMemberIds.value.forEach(member => {
    //   const user = res.members.find(e=>e.user_id == member && e.is_bot == false && e.email != zulipConfigDet.value.username);
    //   if(user){
    //     allUsers.value.push(user);
    //   }
    // });
  }
}
function selectedUser(users) {
  selectedUsers.value = users.map((e) => e.user_id);
}

function getMassaigeSenderName(id) {
  return allUsers.value.find((u) => u.user_id == id)?.full_name;
}

async function getUserGroup(userId) {
  const res = await fetch(`${zulipConfigDet.value.realm}/api/v1/user_groups`, {
    headers: {
      Authorization:
        "Basic " + btoa(`${zulipConfigDet.value.username}:${apiKey.value}`),
    },
  });
  const data = await res.json();
  if (data.result == "success") {
    //filter related user groups
    userGrouplist.value = data.user_groups.filter(
      (e) => e.is_system_group == false && e.members.find((m) => m == userId)
    );
    // let userSToberibed = data.user_groups.filter(e=>e.name==userGroupe.value);
    // groupeMemberIds.value =userSToberibed[0].members;
    // await suscribeStreamByUserGroupe(userSToberibed[0].members);
  }
}
async function suscribeStreamByUserGroupe(members) {
  const anotherUserParams = {
    subscriptions: JSON.stringify([{ name: userGroupe.value }]),
    principals: JSON.stringify(members),
    authorization_errors_fatal: false,
    invite_only: true,
    history_public_to_subscribers: true,
  };
  await zulip.value.users.me.subscriptions.add(anotherUserParams);
}
async function loggedInUserDetails() {
  const userDet = proxy.$serviceHelpers.getDetails("apollodart");
  if (userDet.user) {
    userGroupe.value = `${userDet.user.entity.entity_name}_${userDet.user.entity.sub_entity_name}_${userDet.user.entity.entity_id}`;
  }
}
async function initChatBot() {
  console.log("init chat");
  const zConfig = proxy.$serviceHelpers.getDetails("zulip_details");
  const config = {
    username: zConfig.username,
    apiKey: zConfig.apiKey,
    realm: URLS.HOST_URL.ZULIP_REALM,
  };
  zulipConfigDet.value = config;
  zulip.value = await zulipInit(config);
  if (zulip.value && zulip.value.config.apiKey) {
    apiKey.value = zConfig.apiKey;
    // getPreviousMezFromServer();
    const data = await zulip.value.users.me.getProfile();
    if (data.result === "success") {
      chatBotUser.value = data;
      await getUserGroup(data.user_id);
      await getAllUsers();
    }
    await getEveryEventFromZulip();
  }
}
async function selectedGroupHandler(group) {
  if (group && group.length > 0) {
    selectedGroup.value = group[0].name;
    userGroupe.value = group[0].name;
    selectedGroupUsers.value = [];
    group[0].members.forEach((member) => {
      const user = allUsers.value.find((e) => e.user_id == member);
      if (user) {
        selectedGroupUsers.value.push(user);
      }
    });
    groupeMemberIds.value = group[0].members;
    await getPreviousMezFromServer();
    await suscribeStreamByUserGroupe(group[0].members);
  }
}
async function getEveryEventFromZulip() {
  zulip.value.callOnEachEvent((e) => {
    if (e.type == "message") {
      if (
        (e.message.client == "website" || e.message.client == "Mozilla") &&
        e.message.sender_id != chatBotUser.value.user_id
      ) {
        let uri = "";
        let attachmentName = "";
        if (e.message.content.includes("user_uploads")) {
          const pattern = /\(([^)]+)\)/;
          const matches = e.message.content.match(pattern);
          if (matches) {
            uri = matches[1];
            let tempFile = matches[1].split("/");
            let tempFileLength = tempFile.length;
            attachmentName = tempFile[tempFileLength - 1];
          }
        } 
          totalMesaige.value.push({
            content: e.message.content,
            client:e.message.client,
            uri: uri != "" ? uri : undefined,
            name: uri != "" ? attachmentName : undefined,
            id:e.message.id,
            sender_id: e.message.sender_id,
          });

        nextTick(() => {
          scrollToButtom(true);
        });
      }
    }
  });
}
function draggedHandler(e){
  const totW=window.innerWidth;
  const mWidth = (totW*98)/100;
  const actualPosition = e.pageX<0?Math.abs(5):e.pageX>mWidth?mWidth:e.pageX;
  console.log('e.pageX',e.pageX,'actualPosition:',actualPosition,"mWidth",mWidth);
  chatContainerStyle.left =`${actualPosition}px`;
  // if(actualPosition<410){
    console.log('if',actualPosition+410>mWidth);
    chatBoxStyle.left =`${actualPosition+410>mWidth?actualPosition-410:actualPosition}px`;
  

}
</script>
<template>
  <div class="sticky bottom-0 chatcontainer" draggable="true" @dragend="draggedHandler" :style="chatContainerStyle">
    <button @click="setIsOpen">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8 h-8 text-white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
        />
      </svg>
    </button>

    <div v-if="isOpen" class="chatbox-holder" :style="chatBoxStyle">
      <div class="chatbox">
        <div
          class="flex justify-between px-2 py-2 bg-blue-500 rounded-sm text-white"
        >
          <div class="flex justify-center items-center">
            <div>Support</div>
            <div class="px-2 cursor-pointer" title="Group">
              <!-- User associated groups name -->
              <MultiSelectDropdown
                :lists="userGrouplist"
                display-prop="name"
                value-prop="name"
                :choosed="selectedGroupHandler"
              >
                <template #icon>
                  <UserGroupIcon />
                </template>
              </MultiSelectDropdown>
            </div>
            <div class="px-2 cursor-pointer" title="Users">
              <!-- selected group's users list -->
              <MultiSelectDropdown
                :lists="selectedGroupUsers"
                display-prop="full_name"
                value-prop="user_id"
                :multiple="true"
                :choosed="selectedUser"
              ></MultiSelectDropdown>
            </div>
          </div>
          <div
            @click="setIsOpen"
            class="cursor-pointer hover:bg-red-500 p-1 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>

        <!-- <MultiSelectDropdown  :lists="allUsers" :choosed="selectedUser"/> -->
        <div class="chat-messages">
          <div
            v-for="(msz, i) in totalMesaige"
            :key="i"
            class="message-box-holder"
          >
            <!-- Sent Messaige -->
            <div
              v-if="
                msz.client == 'Mozilla' && chatBotUser.user_id == msz.sender_id
              "
              class="message-box-holder"
            >
              <div class="message-box">
                <a
                  v-if="msz.content.includes('user_uploads')"
                  :href="`https://zulip.apollodart.com${msz.uri}`"
                  target="_blank"
                  title="Click to view"
                >
                  <img
                    :src="`https://zulip.apollodart.com${msz.uri}`"
                    :alt="msz.name"
                    width="180"
                  />
                </a>
                <span v-else> {{ msz.content }}</span>

                <svg
                  class="w-3 h-3 ml-2 cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 4 15"
                  @click="showMoreOptions(msz)"
                >
                  <path
                    d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  />
                </svg>
                <div
                  id="dropdownDots"
                  :class="selectedMszId == msz.id ? '' : 'hidden'"
                >
                  <div
                    v-if="isDeleting"
                    class="w-4 h-4 rounded-full animate-spin border-2 border-solid border-gray-600 border-t-transparen"
                  ></div>
                  <ul
                    v-else
                    class="flex py-1 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownMenuIconButton"
                  >
                    <li
                      v-if="!msz.content.includes('user_uploads')"
                      @click="editMszClickHandler(msz, i)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 cursor-pointer"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </li>
                    <li @click="deleteMszFromserver(msz.id, i)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 cursor-pointer text-red-500"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Received Messaige -->
            <div v-else class="message-box-holder">
              <div class="message-sender">
                {{ getMassaigeSenderName(msz.sender_id) }}
              </div>
              <div class="message-box message-partner">
                <a
                  v-if="msz.content.includes('user_uploads')"
                  :href="`https://zulip.apollodart.com${msz.uri}`"
                  target="_blank"
                  title="Click to view"
                >
                  <img
                    :src="`https://zulip.apollodart.com${msz.uri}`"
                    :alt="msz.name"
                    width="180"
                  />
                </a>
                <span v-else> {{ msz.content }}</span>
              </div>
            </div>
            <!-- Sent Messaige -->
            <!-- <div v-if="msz.client == 'Mozilla'" class="message-box">
              <a
                v-if="msz.content.includes('user_uploads')"
                :href="`https://zulip.apollodart.com${msz.uri}`"
                target="_blank"
                title="Click to view"
              >
                <img
                  :src="`https://zulip.apollodart.com${msz.uri}`"
                  :alt="msz.name"
                  width="180"
                />
              </a>
              <span v-else> {{ msz.content }}</span>

              <svg
                class="w-3 h-3 ml-2 cursor-pointer"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 4 15"
                @click="showMoreOptions(msz)"
              >
                <path
                  d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                />
              </svg>
              <div
                id="dropdownDots"
                :class="selectedMszId == msz.id ? '' : 'hidden'"
              >
                <div
                  v-if="isDeleting"
                  class="w-4 h-4 rounded-full animate-spin border-2 border-solid border-gray-600 border-t-transparen"
                ></div>
                <ul
                  v-else
                  class="flex py-1 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownMenuIconButton"
                >
                  <li
                    v-if="!msz.content.includes('user_uploads')"
                    @click="editMszClickHandler(msz, i)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </li>
                  <li @click="deleteMszFromserver(msz.id, i)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 cursor-pointer text-red-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
            </div> -->
            <!-- Received Messaige -->
            <!-- <div v-else class="message-box message-partner">
            
              <a
                v-if="msz.content.includes('user_uploads')"
                :href="`https://zulip.apollodart.com${msz.uri}`"
                target="_blank"
                title="Click to view"
              >
                <img
                  :src="`https://zulip.apollodart.com${msz.uri}`"
                  :alt="msz.name"
                  width="180"
                />
              </a>
              <span v-else> {{ msz.content }}</span>
            </div> -->
          </div>
          <div
            v-if="totalMesaige.length == 0"
            class="flex flex-col items-center w-full"
          >
            <p class="font-medium">Welcome to Chat.</p>
            <p class="text-xs">To send message Please select Group</p>
          </div>
        </div>

        <div v-if="!isEditMsz" class="chat-input-holder">
          <input
            v-model="typedMessaige"
            @keyup.enter="sendMessaige(false)"
            placeholder="enter to send"
            class="chat-input"
          />
          <div @click="uploadFile" class="attachment-panel">
            <a href="javascript:void(0);" class="fa fa-paperclip"></a>
          </div>
          <button
            @click="sendMessaige(false)"
            type="button"
            class="message-send"
            :disabled="isSending"
          >
            {{ isSending ? "Senging..." : "Send" }}
          </button>
        </div>
        <div v-else class="chat-input-holder">
          <input
            v-model="typedMessaige"
            @keyup.enter="editMessaige(false)"
            placeholder="enter to send"
            class="chat-input"
          />
          <button
            @click="editMessaige(false)"
            type="button"
            class="message-send"
            :disabled="isSending"
          >
            {{ isSending ? "Updating..." : "Update" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.chatcontainer {
  width: 50px;
  background: #0e2f92;
  height: 50px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 9999;
  position: absolute;
  /* right: 1px; */
}
.chatbox-holder {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  height: 0;
  top: 93%;
  padding: 15px;
  /* right: -33px; */
}

.chatbox {
  width: 400px;
  height: 400px;
  margin: 0 20px 0 0;
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  border-radius: 10px 10px 0 0;
  background: var(--sidebarbg);
  bottom: 0;
  transition: 0.1s ease-out;
}

.chatbox-top {
  position: relative;
  display: flex;
  padding: 10px 0;
  border-radius: 10px 10px 0 0;
  background: rgba(5, 5, 6, 0.05);
}

.chat-messages {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px;
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  flex: 1;
}

.message-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}
.message-sender {
  font-size: 12px;
  margin: 0 0 15px;
  color: var(--textColor);
  align-self: flex-start;
}
.message-box {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  background: var(--central_bg);
  /* rgba(100, 170, 0, 0.1); */
  border: 2px solid rgba(100, 170, 0, 0.1);
  color: var(--textColor);
  font-size: 12px;
  display: flex;
  align-items: center;
}

.message-box:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid var(--widgetBg);
  border-right: none;
  bottom: -22px;
  right: 10px;
}

.message-partner {
  background: var(--sidebarListBgColor);
  color: var(--textColor);
  /* rgba(0, 114, 135, 0.1); */
  border: 2px solid rgba(0, 114, 135, 0.1);
  align-self: flex-start;
}

.message-partner:after {
  right: auto;
  bottom: auto;
  top: -22px;
  left: 9px;
  border: 10px solid transparent;
  border-bottom: 10px solid var(--sidebarListBgColor);
  border-left: none;
}

.chat-input-holder {
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.chat-input {
  resize: none;
  padding: 5px 10px;
  height: 40px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #999999;
  flex: 1;
  border: none;
  background: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.chat-input:focus,
.message-send:focus {
  outline: none;
}

.message-send::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.message-send {
  background: #3b82f6;
  color: white;
  font-size: 12px;
  padding: 0 15px;
  border: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.attachment-panel {
  padding: 3px 10px;
  text-align: right;
  color: var(--textColor);
}
</style>
