<script setup>
import { toRefs, ref } from "vue";
import UsersIcon from "./UersIcon.vue";
const props = defineProps({
  lists: {
    type: Array,
    require: true,
    default: [],
  },
  displayProp: {
    type: String,
    require: true,
    default: "full_name",
  },
  valueProp: {
    type: String,
    require: true,
    default: "user_id",
  },
  multiple: {
    type: Boolean,
    require: false,
    default: false,
  },
  choosed: {
    type: Function,
    require: false,
  },
});
const { lists, choosed, displayProp, valueProp,multiple } = toRefs(props);
const isShowDrow = ref(false);
const selected = ref([]);
const selectHadler = (det) => {
  //check if available
  const ind = selected.value.findIndex(
    (e) => e[valueProp.value] == det[valueProp.value]
  );
  if (ind == -1) {
    if(multiple.value){
      selected.value.push(det);
    }else{
      selected.value=[];
      selected.value.push(det);
    }
    choosed.value(selected.value);
  } else {
    selected.value.splice(ind, 1);
    choosed.value(selected.value);
  }
};
const checkedSelected = (id) => {
  //check if available
  const ind = selected.value.findIndex((e) => e[valueProp.value] == id);
  if (ind == -1) {
    return false;
  } else {
    return true;
  }
};
</script>
<template>
  <div class="w-full flex flex-col items-center h-64 mx-auto">
    <div class="w-full">
      <div class="flex flex-col items-center relative">
        <div class="w-full">
          <div>
            <button
              @click="isShowDrow = !isShowDrow"
              class="cursor-pointer w-6 h-6"
            >
              <slot name="icon">
                <UsersIcon/>
              </slot>
            </button>
          </div>
        </div>

        <div
          @mouseleave="isShowDrow = false"
          :class="isShowDrow ? '' : 'hidden'"
          class="absolute shadow top-100 bg-white z-40 lef-0 rounded max-h-select overflow-y-auto text-gray-700"
        >
          <div class="flex flex-col w-full">
            <div
              v-for="list in lists"
              :key="list[valueProp]"
              class="cursor-pointer border-gray-100 rounded-t border-b hover:bg-gray-100 width_min"
            >
              <div
                @click="selectHadler(list)"
                class="flex w-24 items-center px-1 py-1 pl-2 border-transparent border-l-2 relative hover:border-teal-100"
              >
                <div class="flex justify-between">
                  <div class="mx-2 text-md">{{ list[displayProp] }}</div>
                  <div v-if="checkedSelected(list[valueProp])">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.top-100 {
  top: 100%;
}
.bottom-100 {
  bottom: 100%;
}
.max-h-select {
  max-height: 300px;
}
.width_min {
  width: 150px;
}
</style>
